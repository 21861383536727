/*
import "./src/assets/styles/wp-block-library.css"
import "./src/assets/styles/global.css"
import "./src/assets/styles/style.css"
import "./src/assets/styles/style-new.css"
// import "./src/assets/styles/style-rtl.css"
import "./src/assets/styles/print.css"
// import "./src/assets/styles/style-wp-head.css"
*/

/* scrolling behavior on navigation [WORKING!] */

const transitionDelay = 50

exports.shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  // DEFAULT SCROLL BEHAVIOUR
  return true

  // get saved position
  const currentPosition = getSavedScrollPosition(location)
  
  // check #GatsbyBody for accordion
  const body = document.getElementById("GatsbyBody")
  // console.log(body)

  if (body.classList.contains("category")) {
    // category: look for previous position to enable closing of accordion 

    // get previous position
    if (prevRouterProps) {
      const prevPosition = getSavedScrollPosition(prevRouterProps.location)
      console.log(prevRouterProps.location.href, prevPosition)
      window.scrollTo(...(prevPosition || [0, 0]))
    }

  } else if (body.classList.contains("archive-accordion")) {
    // single post with accordion-archive: look for position of active post

    // get active post
    const thisPost = document.getElementsByClassName("post-active")[0]
    // decide whether to return saved position or position of active post
    if (thisPost !== undefined) {
      // return position of active post
      // console.log(thisPost.getBoundingClientRect().top, thisPost.getAttribute("id"))
      // return thisPost.getAttribute("id");
      // return [0, thisPost.getBoundingClientRect().top]
      // window.scrollTo({
      //   top: thisPost.getBoundingClientRect().top,
      //   left: 0,
      //   behavior: "smooth",
      // })
      window.setTimeout(
        () => window.scrollTo(0, thisPost.getBoundingClientRect().top),
        transitionDelay
      )
    } else {
      // return saved position
      // console.log(currentPosition)
      window.scrollTo(...(currentPosition || [0, 0]))
    }
  }

  // return currentPosition
  return false
}


/*
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)

  // window.scrollTo([0, 400])
  // return [0,500]
  return "post-189"
}


const transitionDelay = 0

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  
  window.setTimeout(() => window.scrollTo(0, 500), transitionDelay)
  return false
}
*/