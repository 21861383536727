module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.syjmb.foundation/graphql","verbose":true,"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"debug":{"graphql":{"writeQueriesToDisk":true,"showQueryVarsOnError":false,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"production":{"allow404Images":true,"hardCacheMediaFiles":false},"html":{"useGatsbyImage":true,"createStaticFiles":true,"fallbackImageMaxWidth":840,"imageMaxWidth":null,"imageQuality":90},"schema":{"perPage":50,"timeout":900000,"requestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":null},"MediaItem":{"localFile":{"excludeByMimeTypes":["image/tiff"],"requestConcurrency":5,"maxFileSizeBytes":5242880},"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shizuko Yoshikawa und Josef Müller-Brockmann Stiftung","short_name":"SYJMB Foundation","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"browser","theme_color_in_head":false,"icon":"src/assets/images/icon.png","include_favicon":false,"legacy":false,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"1aa8f209454f72a9d48a92fa47b4ea45"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mailgo/gatsby-browser.js'),
      options: {"plugins":[],"mailgoConfig":{"showFooter":false,"actions":{"whatsapp":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
